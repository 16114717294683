import { useContext, useEffect, useState } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { WalletsContext } from "../context"
import { TTokenData, TWalletData } from "../types"
import { numberToUsdFormat, WalletListBlock } from "./WalletsPage"
import axios from "axios"
import { SERVER_URL, HEADERS } from "../constants"
import { WalletChangeModal } from "./WalletChangeModal"



export const WalletPage = () => {

    const navigate = useNavigate()
    const { id } = useParams()
    const [wallet, setWallet] = useState<TWalletData>()
    const { walletsContext, setWalletsContext } = useContext(WalletsContext)
    const [modalOpen, setModalOpen] = useState<boolean>(false)

    useEffect(() => {
        const setter = () => {
            walletsContext?.forEach((wl) => {
                if (Number(id) == wl.id) {
                    setWallet(wl)
                }
            })
        }
        if (walletsContext) {
            setter()
        }
        else {
            axios.post(SERVER_URL + 'admin/wallet/get/all', {}, {headers: HEADERS})
                .then((response) => {
                    console.log(response)
                    setWalletsContext(response.data)
                    response.data.forEach((wl: TWalletData) => {
                        if (Number(id) == wl.id) {
                            setWallet(wl)
                        }
                    })
                })
                .catch((error) => {
                    console.log(error)
                })
        }
    }, [])

    useEffect(() => {
        const setter = () => {
            walletsContext?.forEach((wl) => {
                if (Number(id) == wl.id) {
                    setWallet(wl)
                }
            })
        }
        setter()
    }, [walletsContext])

    return (
        <>
            <div className="container-fluid">
                <div className="page-title">
                    <div className="row">
                        <div className="col-sm-6">
                            <h3>Кошельёк</h3>
                        </div>
                        <div className="col-sm-6">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <a onClick={() => navigate('/')} data-bs-original-title="" title="">Список кошельков</a>
                                </li>
                                <li className="breadcrumb-item">
                                    <a data-bs-original-title="" title="">Информация о кошельке</a>
                                </li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="card">
                            <div className="card-header">
                                <h4>Информация о кошельке</h4>
                            </div>
                            <div className="card-body">
                                <div id="basicScenario" className="jsgrid">
                                    <div className="jsgrid-grid-body">
                                        <table className="jsgrid-table">
                                            <thead style={{ borderBottom: "1px solid #e9e9e9;" }}>
                                                <tr className="jsgrid-header-row">
                                                    <th className="jsgrid-header-cell jsgrid-header-sortable id">ID</th>
                                                    <th className="jsgrid-header-cell jsgrid-header-sortable seed">Seed</th>
                                                    <th className="jsgrid-header-cell jsgrid-header-sortable default">Готовый?</th>
                                                    <th className="jsgrid-header-cell jsgrid-header-sortable default">Баланс</th>
                                                    <th className="jsgrid-header-cell jsgrid-header-sortable default">Дата создания</th>
                                                    <th className="jsgrid-header-cell jsgrid-header-sortable default">Активный?</th>
                                                    <th className="jsgrid-header-cell jsgrid-header-sortable description">Описание</th>
                                                    <th className="jsgrid-header-cell jsgrid-header-sortable action">Действие</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {wallet ? <WalletListBlock2 walletData={wallet} setModalOpen={setModalOpen} /> : ''}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12">
                        <div className="card">
                            <div className="card-header">
                                <h4>Чат</h4>
                            </div>
                            <div className="card-body" style={{display: "flex", justifyContent: "flex-start", alignItems: "center", gap: "1rem"}}>
                                <button type="submit" data-bs-original-title="" className="all-tokens-btn" title="" onClick={() => navigate('/chat/' + String(wallet?.id))}>Открыть чат</button>
                            </div>
                        </div>
                    </div>
                    <div className="col-sm-12">
                        <div className="card">
                            <div className="card-header">
                                <h4>
                                    Информация о кошельке
                                </h4>
                            </div>
                            <div className="card-body">
                                <div className="row gap-2 gap-md-0 mb-2">
                                    <div className="col-md-6 col-12">
                                        <div className="p-4 text-center list_of_coins" data-hdid="352" onClick={() => navigate('/wallet/tokens/' + id)} style={{backgroundColor: "var(--bs-secondary)", borderRadius: "15px", color: "var(--bs-white)"}}>
                                            <h5 style={{margin: 0}}>Список монет</h5>
                                        </div>
                                    </div>
                                    <div className="col-md-6 col-12">
                                        <div className="p-4 text-center list_of_transactions" data-hdid="352" onClick={() => navigate('/wallet/transactions/' + id)} style={{backgroundColor: "var(--bs-secondary)", borderRadius: "15px", color: "var(--bs-white)"}}>
                                            <h5 style={{margin: 0}}>Список транзакций</h5>
                                        </div>
                                    </div>
                                </div>
                                <div className="row gap-2 gap-md-0">
                                    <div className="col-md-6 col-12">
                                        <div className="p-4 text-center list_of_staking" data-hdid="352" onClick={() => navigate('/wallet/stakings/' + id)} style={{backgroundColor: "var(--bs-secondary)", borderRadius: "15px", color: "var(--bs-white)"}}>
                                            <h5 style={{margin: 0}}>Список стейкингов</h5>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <WalletChangeModal data={{ modalOpen, setModalOpen, wallet, setWallet }}></WalletChangeModal>
        </>
    )
}

export const WalletListBlock2 = ({ walletData, link, setModalOpen }: { walletData: TWalletData, setModalOpen: any, link?: string }) => {

    const [walletBalance, setWalletBalance] = useState<any>()

    useEffect(() => {
        var balance = 0
        walletData?.tokens?.forEach((tk: TTokenData) => {
            balance += Number(numberToUsdFormat(tk.balance, tk.tokenInfo.price))
        })
        setWalletBalance((+balance).toFixed(2).replace(/([0-9]+(\.[0-9]+[1-9])?)(\.?0+$)/,'$1'))
    }, [])

    useEffect(() => {
        var balance = 0
        walletData?.tokens?.forEach((tk: TTokenData) => {
            balance += Number(numberToUsdFormat(tk.balance, tk.tokenInfo.price))
        })
        setWalletBalance((+balance).toFixed(2).replace(/([0-9]+(\.[0-9]+[1-9])?)(\.?0+$)/,'$1'))
    }, [walletData])

    const navigate = useNavigate()

    const clicked = () => {
        if (link) {
            navigate(link)
        }
    }
    return (
        <tr className="jsgrid-row" onClick={clicked}>
            <td className="jsgrid-cell default">ID:{walletData?.id}</td>
            <td className="jsgrid-cell seed">{walletData?.mnemonic}</td>
            <td className="jsgrid-cell default"><div className="flex align-center justify-center"><input type="checkbox" checked={walletData?.completed} /></div></td>
            <td className="jsgrid-cell default">{walletBalance} $</td>
            <td className="jsgrid-cell default">{walletData?.dateCreated?.toString()}</td>
            <td className="jsgrid-cell default"><div className="flex align-center justify-center"><input type="checkbox" checked={walletData?.active} /></div></td>
            <td className="jsgrid-cell description">{walletData?.description}</td>
            <td className="jsgrid-cell jsgrid-control-field jsgrid-align-center action">
                <input className="jsgrid-button jsgrid-edit-button" type="button" title="" data-bs-original-title="Edit" aria-label="Edit" onClick={() => setModalOpen(true)} />
                <input className="jsgrid-button jsgrid-delete-button" type="button" title="" data-bs-original-title="Delete" aria-label="Delete" />
            </td>
        </tr>
    )
}