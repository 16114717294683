import { useContext, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { NetworkDataContext, TokenInfoContext, WalletsContext } from "../context";
import { TTokenData, TTokenInfo } from "../types";
import { STATIC_URL } from "../constants";
import { TokenInfoChangeModal } from "./TokenInfoChangeModal";
import { TokenInfoChangeModal2 } from "./TokenInfoChangeModal2";



export const TokenInfosPage = () => {

    const navigate = useNavigate()
    const { walletsContext, setWalletsContext } = useContext(WalletsContext)
    const { tokenInfoContext, setTokenInfoContext } = useContext(TokenInfoContext)
    const { networkContext, setNetworkContext } = useContext(NetworkDataContext)
    const [modalOpen, setModalOpen] = useState<boolean>(false)
    const [modalOpen2, setModalOpen2] = useState<boolean>(false)
    const [selectedTokenInfo, setSelectedTokenInfo] = useState<TTokenInfo>()

    useEffect(() => {
        tokenInfoContext?.forEach((tk) => {
            if (tk.id == selectedTokenInfo?.id) {
                setSelectedTokenInfo(tk)
            }
        })
    }, [tokenInfoContext])

    return (
        <>
            <div className="container-fluid">
                <div className="page-title">
                    <div className="row">
                        <div className="col-sm-6">
                            <h3>Токены приложения</h3>
                        </div>
                        <div className="col-sm-6">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <a onClick={() => navigate('/')} data-bs-original-title="" title="">Список кошельков</a>
                                </li>
                                <li className="breadcrumb-item">
                                    <a data-bs-original-title="" title="">Информация о токенах</a>
                                </li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="card">
                            <div className="card-header" style={{ display: "flex", justifyContent: "space-between" }}>
                                <h4>Список монет</h4>
                                <input className="jsgrid-button jsgrid-mode-button jsgrid-insert-mode-button" type="button" onClick={() => setModalOpen2(true)} />
                            </div>
                            <div className="card-body">
                                <div className="jsgrid">
                                    <div className="jsgrid-grid-body">
                                        <table className="jsgrid-table">
                                            <thead style={{ borderBottom: "1px solid #e9e9e9;" }}>
                                                <tr className="jsgrid-header-row">
                                                    <th className="jsgrid-header-cell jsgrid-header-sortable default">ID</th>
                                                    <th className="jsgrid-header-cell jsgrid-header-sortable default">Сеть</th>
                                                    <th className="jsgrid-header-cell jsgrid-header-sortable default">Название</th>
                                                    <th className="jsgrid-header-cell jsgrid-header-sortable default">Символьное</th>
                                                    <th className="jsgrid-header-cell jsgrid-header-sortable default">Иконка</th>
                                                    <th className="jsgrid-header-cell jsgrid-header-sortable default">Активный</th>
                                                    <th className="jsgrid-header-cell jsgrid-header-sortable default">Мемо</th>
                                                    <th className="jsgrid-header-cell jsgrid-header-sortable default">CoinMarketCupID</th>
                                                    <th className="jsgrid-header-cell jsgrid-header-sortable action">Действие</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {tokenInfoContext?.map((tk: TTokenInfo) => <TokenListBlock tokenInfo={tk} modalOpen={modalOpen} setModalOpen={setModalOpen} setSelectedTokenInfo={setSelectedTokenInfo} />)}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <TokenInfoChangeModal data={{ modalOpen, setModalOpen, selectedTokenInfo, setSelectedTokenInfo}} />
            <TokenInfoChangeModal2 data={{ modalOpen: modalOpen2, setModalOpen: setModalOpen2}} />
        </>
    )
}

const TokenListBlock = ({ tokenInfo, modalOpen, setModalOpen, setSelectedTokenInfo }: { tokenInfo: TTokenInfo, modalOpen: boolean, setModalOpen: any, setSelectedTokenInfo: any }) => {
    return (
        <tr className="jsgrid-row">
            <td className="jsgrid-cell default">{tokenInfo?.id}</td>
            <td className="jsgrid-cell default">{tokenInfo?.networkName}({tokenInfo?.networkSymbol})</td>
            <td className="jsgrid-cell default">{tokenInfo?.name}</td>
            <td className="jsgrid-cell default">{tokenInfo?.symbol}</td>
            <td className="jsgrid-cell default"><img alt={tokenInfo?.name} src={tokenInfo?.icon.includes("http") ? tokenInfo?.icon : STATIC_URL + tokenInfo?.icon} className="rounded-full border-1" width="30px" height="30px" /></td>
            <td className="jsgrid-cell default"><input type="checkbox" checked={tokenInfo?.active} /></td>
            <td className="jsgrid-cell default"><input type="checkbox" checked={tokenInfo?.memo} /></td>
            <td className="jsgrid-cell default">{tokenInfo?.coinMarketCupId}</td>
            <td className="jsgrid-cell jsgrid-control-field jsgrid-align-center action">
                <input className="jsgrid-button jsgrid-edit-button" type="button" title="" data-bs-original-title="Edit" aria-label="Edit" onClick={() => {setSelectedTokenInfo(tokenInfo); setModalOpen(true)}} />
                <input className="jsgrid-button jsgrid-delete-button" type="button" title="" data-bs-original-title="Delete" aria-label="Delete" />
            </td>
        </tr>
    )
}