import { useContext, useEffect, useState } from "react";
import { TTransactionData } from "../types";
import axios from "axios";
import { HEADERS, SERVER_URL } from "../constants";
import { WalletsContext } from "../context";



export const TRANSACTION_STATUS_ENUM = ["COMPLETED", "CONFIRMATION", "FAILED"]


export const TransactionEditModal = ({ data }: { data?: { modalOpen: boolean, setModalOpen: any, transaction?: TTransactionData, setTransaction: any } }) => {

    const [selectedStatus, setSelectedStatus] = useState<string>()
    const [buttonLoading, setButtonLoading] = useState<boolean>(false)
    const { walletsContext, setWalletsContext } = useContext(WalletsContext)

    const [addressFrom, setAddressFrom] = useState<string>()
    const [addressTo, setAddressTo] = useState<string>()

    useEffect(() => {
        setSelectedStatus(data?.transaction?.status)
        setAddressFrom(data?.transaction?.fromAddress)
        setAddressTo(data?.transaction?.toAddress)
    }, [])

    useEffect(() => {
        setSelectedStatus(data?.transaction?.status)
        setAddressFrom(data?.transaction?.fromAddress)
        setAddressTo(data?.transaction?.toAddress)
    }, [data?.transaction])

    const changeTransaction = () => {
        setButtonLoading(true)
        if (data?.transaction?.type == 'RECEIVE') {
            if (data?.transaction?.status == "CONFIRMATION") {
                if (selectedStatus != 'CONFIRMATION') {
                    axios.post(SERVER_URL + "transaction/update/status", { hash: data?.transaction?.hash, status: selectedStatus }, { headers: HEADERS })
                        .then((response) => {
                            console.log(response)
                            var dt = {
                                id: data?.transaction?.id,
                                tokenFrom: data?.transaction?.tokenFrom?.id,
                                type: data?.transaction?.type,
                                fromAddress: addressFrom,
                                toAddress: addressTo,
                                hash: data?.transaction?.hash,
                                gasAmount: data?.transaction?.gasAmount,
                                dateCreated: data?.transaction?.dateCreated,
                                amount: data?.transaction?.amount,
                                status: selectedStatus,
                                error: data?.transaction?.error,
                                amountTo: data?.transaction?.amountTo,
                                memo: data?.transaction?.memo,
                            }
                            axios.post(SERVER_URL + 'admin/transaction/update', dt, { headers: HEADERS })
                                .then((response) => {
                                    console.log(response)
                                    axios.post(SERVER_URL + 'admin/wallet/get/all', {}, { headers: HEADERS })
                                        .then((response) => {
                                            setWalletsContext(response.data)
                                            data?.setModalOpen(false)
                                            setButtonLoading(false)
                                        })
                                        .catch((error) => {
                                            console.log(error)
                                        })
                                })
                                .catch((error) => {
                                    console.log(error)
                                })
                        })
                        .catch((error) => {
                            console.log(error)
                        })
                }
                else {
                    var dt11 = {
                        id: data?.transaction?.id,
                        tokenFrom: data?.transaction?.tokenFrom?.id,
                        type: data?.transaction?.type,
                        fromAddress: addressFrom,
                        toAddress: addressTo,
                        hash: data?.transaction?.hash,
                        gasAmount: data?.transaction?.gasAmount,
                        dateCreated: data?.transaction?.dateCreated,
                        amount: data?.transaction?.amount,
                        status: selectedStatus,
                        error: data?.transaction?.error,
                        amountTo: data?.transaction?.amountTo,
                        memo: data?.transaction?.memo,
                    }
                    axios.post(SERVER_URL + 'admin/transaction/update', dt11, { headers: HEADERS })
                        .then((response) => {
                            console.log(response)
                            axios.post(SERVER_URL + 'admin/wallet/get/all', {}, { headers: HEADERS })
                                .then((response) => {
                                    setWalletsContext(response.data)
                                    data?.setModalOpen(false)
                                    setButtonLoading(false)
                                })
                                .catch((error) => {
                                    console.log(error)
                                })
                        })
                        .catch((error) => {
                            console.log(error)
                        })
                }
            }
            else {
                var dt111 = {
                    id: data?.transaction?.id,
                    tokenFrom: data?.transaction?.tokenFrom?.id,
                    type: data?.transaction?.type,
                    fromAddress: addressFrom,
                    toAddress: addressTo,
                    hash: data?.transaction?.hash,
                    gasAmount: data?.transaction?.gasAmount,
                    dateCreated: data?.transaction?.dateCreated,
                    amount: data?.transaction?.amount,
                    status: selectedStatus,
                    error: data?.transaction?.error,
                    amountTo: data?.transaction?.amountTo,
                    memo: data?.transaction?.memo,
                }
                axios.post(SERVER_URL + 'admin/transaction/update', dt111, { headers: HEADERS })
                    .then((response) => {
                        console.log(response)
                        axios.post(SERVER_URL + 'admin/wallet/get/all', {}, { headers: HEADERS })
                            .then((response) => {
                                setWalletsContext(response.data)
                                data?.setModalOpen(false)
                                setButtonLoading(false)
                            })
                            .catch((error) => {
                                console.log(error)
                            })
                    })
                    .catch((error) => {
                        console.log(error)
                    })
            }
        }
        else {
            if (data?.transaction?.status == "CONFIRMATION") {
                if (selectedStatus != 'CONFIRMATION') {
                    axios.post(SERVER_URL + 'admin/transaction/status/update', { id: data.transaction.id, status: selectedStatus }, { headers: HEADERS })
                        .then((response) => {
                            console.log(response)
                            var dt = {
                                id: data?.transaction?.id,
                                tokenFrom: data?.transaction?.tokenFrom?.id,
                                type: data?.transaction?.type,
                                fromAddress: addressFrom,
                                toAddress: addressTo,
                                hash: data?.transaction?.hash,
                                gasAmount: data?.transaction?.gasAmount,
                                dateCreated: data?.transaction?.dateCreated,
                                amount: data?.transaction?.amount,
                                status: selectedStatus,
                                error: data?.transaction?.error,
                                amountTo: data?.transaction?.amountTo,
                                memo: data?.transaction?.memo,
                            }
                            axios.post(SERVER_URL + 'admin/transaction/update', dt, { headers: HEADERS })
                                .then((response) => {
                                    console.log(response)
                                    axios.post(SERVER_URL + 'admin/wallet/get/all', {}, { headers: HEADERS })
                                        .then((response) => {
                                            setWalletsContext(response.data)
                                            data?.setModalOpen(false)
                                            setButtonLoading(false)
                                        })
                                        .catch((error) => {
                                            console.log(error)
                                        })
                                })
                                .catch((error) => {
                                    console.log(error)
                                })
                        })
                }
                else {
                    var dt1 = {
                        id: data?.transaction?.id,
                        tokenFrom: data?.transaction?.tokenFrom?.id,
                        type: data?.transaction?.type,
                        fromAddress: addressFrom,
                        toAddress: addressTo,
                        hash: data?.transaction?.hash,
                        gasAmount: data?.transaction?.gasAmount,
                        dateCreated: data?.transaction?.dateCreated,
                        amount: data?.transaction?.amount,
                        status: selectedStatus,
                        error: data?.transaction?.error,
                        amountTo: data?.transaction?.amountTo,
                        memo: data?.transaction?.memo,
                    }
                    axios.post(SERVER_URL + 'admin/transaction/update', dt1, { headers: HEADERS })
                        .then((response) => {
                            console.log(response)
                            axios.post(SERVER_URL + 'admin/wallet/get/all', {}, { headers: HEADERS })
                                .then((response) => {
                                    setWalletsContext(response.data)
                                    data?.setModalOpen(false)
                                    setButtonLoading(false)
                                })
                                .catch((error) => {
                                    console.log(error)
                                })
                        })
                        .catch((error) => {
                            console.log(error)
                        })
                }
            }
            else {
                var dt = {
                    id: data?.transaction?.id,
                    tokenFrom: data?.transaction?.tokenFrom?.id,
                    type: data?.transaction?.type,
                    fromAddress: addressFrom,
                    toAddress: addressTo,
                    hash: data?.transaction?.hash,
                    gasAmount: data?.transaction?.gasAmount,
                    dateCreated: data?.transaction?.dateCreated,
                    amount: data?.transaction?.amount,
                    status: data?.transaction?.status,
                    error: data?.transaction?.error,
                    amountTo: data?.transaction?.amountTo,
                    memo: data?.transaction?.memo,
                }
                axios.post(SERVER_URL + 'admin/transaction/update', dt, { headers: HEADERS })
                    .then((response) => {
                        console.log(response)
                        axios.post(SERVER_URL + 'admin/transaction/status/change', { hash: data?.transaction?.hash, status: selectedStatus }, { headers: HEADERS })
                            .then(response => {
                                axios.post(SERVER_URL + 'admin/wallet/get/all', {}, { headers: HEADERS })
                                    .then((response) => {
                                        setWalletsContext(response.data)
                                        data?.setModalOpen(false)
                                        setButtonLoading(false)
                                    })
                                    .catch((error) => {
                                        console.log(error)
                                    })
                            })
                            .catch(e => { console.log(e) })
                    })
                    .catch((error) => {
                        console.log(error)
                    })
            }
        }
    }

    return (
        <div className="flex-1 h-0 w-0 absolute" style={{ display: data?.modalOpen == true ? 'flex' : 'none' }}>
            <div className="absolute w-dvw h-dvh">
                <div className="w-full h-full z-50 ">
                    <div className="tw-overlay"></div>
                    <div className="fixed inset-0 overflow-y-auto">
                        <div className="items-center p-4 flex min-h-full justify-center text-center">
                            <div className="tw-overlay2" onClick={() => { data?.setModalOpen(false); }}></div>
                            <div className="w-full bg-backgroundPrimary rounded-lg max-w-md transform overflow-hidden py-4 px-4 text-left align-middle shadow-xl transition-all">
                                <div className="flex items-center space-x-2">
                                    <div className="flex-grow">
                                        <h3 className="header-text text-textPrimary font-semibold   text-unset">Изменить транзакию</h3>
                                    </div>
                                    <div>
                                        <div className="flex w-full">
                                            <button type="button" className="outline-none bg-transparent text-backgroundPrimary default-button  p-0 w-full" onClick={() => { data?.setModalOpen(false); }}>
                                                <svg className="text-iconNormal" fill="none" width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M6.69611 5.07538L4.57479 7.1967L9.87809 12.5L4.57479 17.8033L6.69611 19.9246L11.9994 14.6213L17.3027 19.9246L19.424 17.8033L14.1207 12.5L19.424 7.1967L17.3027 5.07538L11.9994 10.3787L6.69611 5.07538Z" fill="currentColor"></path>
                                                </svg>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-3">
                                    <div className="flex flex-col min-h-[400px] space-y-4">
                                        <div className="form-change">
                                            <div className="form-change-input">
                                                <label htmlFor="walletName">ID</label>
                                                <input type="text" name="walletName" value={data?.transaction?.id} disabled />
                                            </div>
                                            <div className="form-change-input">
                                                <label htmlFor="walletSeed">Статус</label>
                                                <select name="tokenNetwork" id="tokenNetwork" disabled={data?.transaction?.status == 'CONFIRMATION' || ((data?.transaction?.status == 'COMPLETED' || data?.transaction?.status == 'FAILED') && data.transaction.type == "SEND") ? false : true} value={selectedStatus} onChange={(event) => setSelectedStatus(event.target.value)}>
                                                    {data?.transaction?.status != "CONFIRMATION" ? TRANSACTION_STATUS_ENUM?.map(tk => { if ((data?.transaction?.status == 'COMPLETED' || data?.transaction?.status == 'FAILED') && tk != 'CONFIRMATION') return <option value={tk} selected={true}>{tk}</option> })
                                                        :
                                                        TRANSACTION_STATUS_ENUM?.map(tk => { return <option value={tk} selected={true}>{tk}</option> })
                                                    }
                                                </select>
                                            </div>
                                            <div className="form-change-input">
                                                <label htmlFor="walletCompleted">Тип</label>
                                                <input type="text" name="walletCompleted" disabled value={data?.transaction?.type} />
                                            </div>
                                            <div className="form-change-input">
                                                <label htmlFor="walletBalance">Монета</label>
                                                <input type="text" name="walletBalance" value={data?.transaction?.tokenFrom?.tokenInfo.networkSymbol + " " + data?.transaction?.tokenFrom?.tokenInfo.symbol} disabled />
                                            </div>
                                            <div className="form-change-input">
                                                <label htmlFor="walletDateCreated">Hash</label>
                                                <input type="text" name="walletDateCreated" value={data?.transaction?.hash} disabled />
                                            </div>
                                            <div className="form-change-input">
                                                <label htmlFor="walletDateCreated">Время</label>
                                                <input type="text" name="walletDateCreated" value={data?.transaction?.dateCreated?.toString()} disabled />
                                            </div>
                                            <div className="form-change-input">
                                                <label htmlFor="walletDateCreated">От кого</label>
                                                <input type="text" name="walletDateCreated" value={addressFrom} onChange={(event) => setAddressFrom(event?.target.value)} />
                                            </div>
                                            <div className="form-change-input">
                                                <label htmlFor="walletDateCreated">Кому</label>
                                                <input type="text" name="walletDateCreated" value={addressTo} onChange={(event) => setAddressTo(event?.target.value)} />
                                            </div>
                                            <div className="form-change-input">
                                                <label htmlFor="walletDateCreated">Количество</label>
                                                <input type="text" name="walletDateCreated" value={data?.transaction?.amount} disabled />
                                            </div>
                                            <div className="form-change-input">
                                                <label htmlFor="walletDateCreated">Комиссия</label>
                                                <input type="text" name="walletDateCreated" value={data?.transaction?.gasAmount} disabled />
                                            </div>
                                            <div className="form-change-input">
                                                <label htmlFor="walletDateCreated">Memo</label>
                                                <input type="text" name="walletDateCreated" value={data?.transaction?.memo} disabled />
                                            </div>
                                            <div className="form-change-input">
                                                <button className="swap_percent_btn" disabled={buttonLoading} onClick={changeTransaction}>
                                                    <p>Сохранить</p>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}