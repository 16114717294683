import React, { useState } from 'react';
import logo from './logo.svg';
import './App.css';
import { WalletsContext, NetworkDataContext, StakingDataContext, ProgramContext, TokenInfoContext, ChatContext } from './context';
import { TChatData, TNetworkData, TProgramData, TStakeInfo, TStakePool, TTokenInfo, TWalletData } from './types';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { Layout } from './Layout';
import { WalletsPage } from './pages/WalletsPage';
import { WalletPage } from './pages/WalletPage';
import { TokensPage } from './pages/TokensPage';
import { TransactionsPage } from './pages/TransactionsPage';
import { StakingsPage } from './pages/StakingsPage';
import { TokenInfosPage } from './pages/TokenInfosPage';
import { StakingsPoolPage } from './pages/StakingsPoolPage';
import { WalletChatPage } from './pages/WalletChatPage';
import Login from './Login';
import axios from 'axios';
import { HEADERS, SERVER_URL } from './constants';


const getToken = () => {
  const tokenString: any = sessionStorage.getItem('token');
  return tokenString
};

export const setSessionToken = (userToken: any) => {
  sessionStorage.setItem('token', JSON.stringify(userToken));
};

function App() {
  const [walletsContext, setWalletsContext] = useState<TWalletData[] | undefined>();
  const [networkContext, setNetworkContext] = useState<TNetworkData[] | undefined>();
  const [stakingContext, setStakingContext] = useState<TStakeInfo[] | undefined>();
  const [programContext, setProgramContext] = useState<TProgramData | undefined>();
  const [tokenInfoContext, setTokenInfoContext] = useState<TTokenInfo[] | undefined>();
  const [chatContext, setChatContext] = useState<TChatData | undefined>();
  const [token, setToken] = useState(getToken());
  const [tokenCorrect, setTokenCorrect] = useState<boolean>(false)

  if (!token) {
    return (
      <Login setToken={setToken} />
    )
  }
  else if (tokenCorrect == false) {
    axios.post(SERVER_URL + 'admin/user/token', { token: token.replace(/[^+\da-z\-]/g, '') }, { headers: HEADERS })
      .then(response => {
        setTokenCorrect(true)
      })
      .catch(e => {console.log(e);setTokenCorrect(false)})
    return (
      <></>
    )
  }
  else {
    return (
      <WalletsContext.Provider value={{ walletsContext, setWalletsContext }}>
        <NetworkDataContext.Provider value={{ networkContext, setNetworkContext }}>
          <StakingDataContext.Provider value={{ stakingContext, setStakingContext }}>
            <ProgramContext.Provider value={{ programContext, setProgramContext }}>
              <TokenInfoContext.Provider value={{ tokenInfoContext, setTokenInfoContext }}>
                <ChatContext.Provider value={{ chatContext, setChatContext }}>
                  <BrowserRouter>
                    <Routes>
                      <Route path='/' element={<Layout setToken={setToken} setSessionToken={setSessionToken} token={token} />}>
                        <Route index element={<WalletsPage />} />
                        <Route path='/wallet/:id' element={<WalletPage />} />
                        <Route path='/chat/:id' element={<WalletChatPage />} />
                        <Route path='/wallet/tokens/:id' element={<TokensPage />} />
                        <Route path='/wallet/transactions/:id' element={<TransactionsPage />} />
                        <Route path='/wallet/stakings/:id' element={<StakingsPage />} />
                        <Route path='/tokens/' element={<TokenInfosPage />} />
                        <Route path='/stakings/' element={<StakingsPoolPage />} />
                      </Route>
                    </Routes>
                  </BrowserRouter>
                </ChatContext.Provider>
              </TokenInfoContext.Provider>
            </ProgramContext.Provider>
          </StakingDataContext.Provider>
        </NetworkDataContext.Provider>
      </WalletsContext.Provider>
    );
  }
}

export default App;
