import { useState, useContext, useEffect } from "react";
import { WalletsContext, TokenInfoContext, NetworkDataContext } from "../context";
import { TStakeData, TStakePool } from "../types";
import axios from "axios";
import { HEADERS, SERVER_URL } from "../constants";
import { error } from "console";




export const StakingPoolEditModal = ({ data }: { data: { modalOpen: boolean, setModalOpen: any, stakingPool?: TStakePool } }) => {
    const [buttonLoading, setButtonLoading] = useState<boolean>(false)
    const { walletsContext, setWalletsContext } = useContext(WalletsContext)
    const { tokenInfoContext, setTokenInfoContext } = useContext(TokenInfoContext)
    const { networkContext, setNetworkContext } = useContext(NetworkDataContext)

    const [reward, setReward] = useState<number>()
    const [amount, setAmount] = useState<number>()
    const [finalize, setFinalize] = useState<boolean>()
    const [payout, setPayout] = useState<boolean>()

    useEffect(() => {
        var st = { amount: 0, reward: 0 - (data?.stakingPool?.rewarded ? data.stakingPool?.rewarded : 0) }
        data?.stakingPool?.stakeList.forEach((stake: TStakeData) => {
            st.amount += stake.amount
            st.reward += stake.reward
        })
        setReward(st.reward)
        setAmount(st.amount)
        setFinalize(data?.stakingPool?.finalize)
        setPayout(data?.stakingPool?.payout)
    }, [])

    useEffect(() => {
        var st = { amount: 0, reward: 0 - (data?.stakingPool?.rewarded ? data.stakingPool?.rewarded : 0) }
        data?.stakingPool?.stakeList.forEach((stake: TStakeData) => {
            st.amount += stake.amount
            st.reward += stake.reward
        })
        setReward(st.reward)
        setAmount(st.amount)
        setFinalize(data?.stakingPool?.finalize)
        setPayout(data?.stakingPool?.payout)
    }, [data?.stakingPool])

    const changeStaking = () => {
        setButtonLoading(true)
        if (data?.stakingPool?.payout != payout) {
            axios.post(SERVER_URL + 'admin/stakepool/update/payout', { id: data?.stakingPool?.id, payout: payout }, { headers: HEADERS })
                .then((response) => {
                    console.log(response)
                    if (data?.stakingPool?.finalize != finalize) {
                        axios.post(SERVER_URL + 'admin/stakepool/update/finalize', { id: data?.stakingPool?.id, finalize: finalize }, { headers: HEADERS })
                            .then((response) => {
                                console.log(response)
                                if (finalize == true) {
                                    axios.post(SERVER_URL + 'admin/stakepool/update/reward', { id: data?.stakingPool?.id, rewarded: reward }, { headers: HEADERS })
                                        .then((response) => {
                                            console.log(response)
                                            axios.post(SERVER_URL + 'admin/wallet/get/all', {}, { headers: HEADERS })
                                                .then((response) => {
                                                    setWalletsContext(response.data)
                                                    data?.setModalOpen(false)
                                                    setButtonLoading(false)
                                                })
                                                .catch((error) => {
                                                    console.log(error)
                                                })
                                        })
                                        .catch((error) => {
                                            console.log(error)
                                        })
                                }
                                else {
                                    data?.setModalOpen(false)
                                    setButtonLoading(false)
                                }
                            })
                            .catch((error) => {
                                console.log(error)
                            })
                    }
                    else {
                        data?.setModalOpen(false)
                        setButtonLoading(false)
                    }
                })
                .catch((error) => {
                    console.log(error)
                })
        }
        else if (data?.stakingPool?.finalize != finalize) {
            axios.post(SERVER_URL + 'admin/stakepool/update/finalize', { id: data?.stakingPool?.id, finalize: finalize }, { headers: HEADERS })
                .then((response) => {
                    console.log(response)
                    if (finalize == true) {
                        axios.post(SERVER_URL + 'admin/stakepool/update/reward', { id: data?.stakingPool?.id, rewarded: reward }, { headers: HEADERS })
                            .then((response) => {
                                console.log(response)
                                axios.post(SERVER_URL + 'admin/wallet/get/all', {}, { headers: HEADERS })
                                    .then((response) => {
                                        setWalletsContext(response.data)
                                        data?.setModalOpen(false)
                                        setButtonLoading(false)
                                    })
                                    .catch((error) => {
                                        console.log(error)
                                    })
                            })
                            .catch((error) => {
                                console.log(error)
                            })
                    }
                    else {
                        data?.setModalOpen(false)
                        setButtonLoading(false)
                    }
                })
                .catch((error) => {
                    console.log(error)
                })
        }
        else if (finalize == true) {
            axios.post(SERVER_URL + 'admin/stakepool/update/reward', { id: data?.stakingPool?.id, rewarded: reward }, { headers: HEADERS })
                .then((response) => {
                    console.log(response)
                    axios.post(SERVER_URL + 'admin/wallet/get/all', {}, { headers: HEADERS })
                        .then((response) => {
                            setWalletsContext(response.data)
                            data?.setModalOpen(false)
                            setButtonLoading(false)
                        })
                        .catch((error) => {
                            console.log(error)
                        })
                })
                .catch((error) => {
                    console.log(error)
                })
        }
        else {
            data?.setModalOpen(false)
            setButtonLoading(false)
        }
    }

    return (
        <div className="flex-1 h-0 w-0 absolute" style={{ display: data?.modalOpen == true ? 'flex' : 'none' }}>
            <div className="absolute w-dvw h-dvh">
                <div className="w-full h-full z-50 ">
                    <div className="tw-overlay"></div>
                    <div className="fixed inset-0 overflow-y-auto">
                        <div className="items-center p-4 flex min-h-full justify-center text-center">
                            <div className="tw-overlay2" onClick={() => { data?.setModalOpen(false); }}></div>
                            <div className="w-full bg-backgroundPrimary rounded-lg max-w-md transform overflow-hidden py-4 px-4 text-left align-middle shadow-xl transition-all">
                                <div className="flex items-center space-x-2">
                                    <div className="flex-grow">
                                        <h3 className="header-text text-textPrimary font-semibold   text-unset">Изменить стейкинг</h3>
                                    </div>
                                    <div>
                                        <div className="flex w-full">
                                            <button type="button" className="outline-none bg-transparent text-backgroundPrimary default-button  p-0 w-full" onClick={() => { data?.setModalOpen(false); }}>
                                                <svg className="text-iconNormal" fill="none" width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M6.69611 5.07538L4.57479 7.1967L9.87809 12.5L4.57479 17.8033L6.69611 19.9246L11.9994 14.6213L17.3027 19.9246L19.424 17.8033L14.1207 12.5L19.424 7.1967L17.3027 5.07538L11.9994 10.3787L6.69611 5.07538Z" fill="currentColor"></path>
                                                </svg>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-3">
                                    <div className="flex flex-col min-h-[400px] space-y-4">
                                        <div className="form-change">
                                            <div className="form-change-input">
                                                <label htmlFor="walletSeed">ID</label>
                                                <input name="walletSeed" value={data?.stakingPool?.id} disabled />
                                            </div>
                                            <div className="form-change-input">
                                                <label htmlFor="walletName">Сеть</label>
                                                <input name="walletSeed" value={data?.stakingPool?.tokenInfoModel?.networkSymbol + "(" + data?.stakingPool?.tokenInfoModel?.symbol + ")"} disabled />
                                            </div>
                                            <div className="form-change-input">
                                                <label htmlFor="walletSeed">Сумма</label>
                                                <input name="walletSeed" value={amount} disabled />
                                            </div>
                                            <div className="form-change-input">
                                                <label htmlFor="walletSeed">Награда</label>
                                                <input name="walletSeed" value={reward} onChange={(event) => setReward(Number(event.target.value))} />
                                            </div>
                                            <div className="form-change-input">
                                                <label htmlFor="walletSeed">Разрешение на вывод</label>
                                                <input type="checkbox" checked={payout} onChange={(event) => setPayout(!payout)} />
                                            </div>
                                            <div className="form-change-input">
                                                <label htmlFor="walletSeed">Завершен</label>
                                                <input type="checkbox" checked={finalize} onChange={(event) => setFinalize(!finalize)} />
                                            </div>
                                            <div className="form-change-input">
                                                <label htmlFor="walletSeed">Время</label>
                                                <input name="walletSeed" value={data?.stakingPool?.dateCreated.toString()} disabled />
                                            </div>
                                            <div className="form-change-input">
                                                <label htmlFor="walletSeed">Выведено награды</label>
                                                <input name="walletSeed" value={data?.stakingPool?.rewarded} disabled />
                                            </div>
                                            <div className="form-change-input">
                                                <button className="swap_percent_btn" disabled={buttonLoading} onClick={changeStaking}>
                                                    <p>Сохранить</p>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}