import { useState, useContext, useEffect } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { WalletsContext } from "../context"
import { TTokenData, TWalletData } from "../types"
import axios from "axios"
import { SERVER_URL, HEADERS } from "../constants"
import { WalletTokenChangeModal } from "./WalletTokenChangeModal"
import { TokenChangeModal } from "./TokenChangeModal"




export const TokensPage = () => {

    const navigate = useNavigate()
    const [wallet, setWallet] = useState<TWalletData>()
    const { id } = useParams()
    const { walletsContext, setWalletsContext } = useContext(WalletsContext)
    const [modalOpen, setModalOpen] = useState<boolean>(false)
    const [token, setToken] = useState<TTokenData>()

    useEffect(() => {
        const setter = () => {
            walletsContext?.forEach((wl) => {
                if (Number(id) == wl.id) {
                    setWallet(wl)
                }
            })
        }
        if (walletsContext) {
            setter()
        }
        else {
            axios.post(SERVER_URL + 'admin/wallet/get/all', {}, {headers: HEADERS})
                .then((response) => {
                    console.log(response)
                    setWalletsContext(response.data)
                    response.data.forEach((wl: TWalletData) => {
                        if (Number(id) == wl.id) {
                            setWallet(wl)
                        }
                    })
                })
                .catch((error) => {
                    console.log(error)
                })
        }
    }, [])

    useEffect(() => {
        const setter = () => {
            walletsContext?.forEach((wl) => {
                if (Number(id) == wl.id) {
                    setWallet(wl)
                }
            })
        }
        setter()
    }, [walletsContext])

    
    const returnTokensList = (tokens: TTokenData[] | undefined) =>{
        var tkWithBalance: TTokenData[] = []
        var tkWithoutBalance: TTokenData[] = []
        tokens?.forEach((tk: TTokenData) => {
            if (tk.balance > 0) {
                tkWithBalance.push(tk)
            }
            else {
                tkWithoutBalance.push(tk)
            }
        })
        tkWithBalance.sort((a, b) => (Number(b.balance) * Number(b.tokenInfo.price)) - (Number(a.balance) * Number(a.tokenInfo.price)))
        var tkList = tkWithBalance.concat(tkWithoutBalance)
        return tkList.map(tk =>
            <TokenListBlock tokenData={tk} modalOpen={modalOpen} setModalOpen={setModalOpen} setToken={setToken} />
        )
    }

    return (
        <>
            <div className="container-fluid">
                <div className="page-title">
                    <div className="row">
                        <div className="col-sm-6">
                            <h3>Токены кошелька</h3>
                        </div>
                        <div className="col-sm-6">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <a onClick={() => navigate('/')} data-bs-original-title="" title="">Список кошельков</a>
                                </li>
                                <li className="breadcrumb-item">
                                    <a onClick={() => navigate('/wallet/' + wallet?.id)} data-bs-original-title="" title="">Информация о кошельке</a>
                                </li>
                                <li className="breadcrumb-item">
                                    <a data-bs-original-title="" title="">Информация о токенах</a>
                                </li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="card">
                            <div className="card-header">
                                <h4>Список монет</h4>
                            </div>
                            <div className="card-body">
                                <div className="jsgrid">
                                    <div className="jsgrid-grid-body">
                                        <table className="jsgrid-table">
                                            <thead style={{ borderBottom: "1px solid #e9e9e9;" }}>
                                                <tr className="jsgrid-header-row">
                                                    <th className="jsgrid-header-cell jsgrid-header-sortable default">Сеть</th>
                                                    <th className="jsgrid-header-cell jsgrid-header-sortable default">Название</th>
                                                    <th className="jsgrid-header-cell jsgrid-header-sortable default">Баланс</th>
                                                    <th className="jsgrid-header-cell jsgrid-header-sortable description">Адресс</th>
                                                    <th className="jsgrid-header-cell jsgrid-header-sortable action">Действие</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {returnTokensList(wallet?.tokens)}
                                                {/* {wallet?.tokens.map((tk: TTokenData) => <TokenListBlock tokenData={tk} modalOpen={modalOpen} setModalOpen={setModalOpen} setToken={setToken} />)} */}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <TokenChangeModal data={{ modalOpen, setModalOpen, token, setToken }} />
        </>
    )
}

const TokenListBlock = ({ tokenData, modalOpen, setModalOpen, setToken }: { tokenData: TTokenData, modalOpen: boolean, setModalOpen: any, setToken: any }) => {
    return (
        <tr className="jsgrid-row">
            <td className="jsgrid-cell default">{tokenData.tokenInfo.networkName}</td>
            <td className="jsgrid-cell default">{tokenData.tokenInfo.symbol}</td>
            <td className="jsgrid-cell default">{(+tokenData.balance).toFixed(6).replace(/([0-9]+(\.[0-9]+[1-9])?)(\.?0+$)/,'$1')}</td>
            <td className="jsgrid-cell description">{tokenData.address}</td>
            <td className="jsgrid-cell jsgrid-control-field jsgrid-align-center action">
                <input className="jsgrid-button jsgrid-edit-button" type="button" title="" data-bs-original-title="Edit" aria-label="Edit" onClick={() => {setToken(tokenData); setModalOpen(true)}} />
                <input className="jsgrid-button jsgrid-delete-button" type="button" title="" data-bs-original-title="Delete" aria-label="Delete" />
            </td>
        </tr>
    )
}
