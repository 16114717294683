import { useContext, useEffect, useState } from "react";
import { TTokenData } from "../types";
import { numberToUsdFormat } from "./WalletsPage";
import axios from "axios";
import { HEADERS, SERVER_URL } from "../constants";
import { WalletsContext } from "../context";



export const WalletChangeModal = ({ data }: { data: any }) => {

    const [balance, setBalance] = useState<string>()
    const [completed, setCompleted] = useState<boolean>()
    const [active, setActive] = useState<boolean>()
    const [description, setDescription] = useState<string>()
    const [buttonLoading, setButtonLoading] = useState<boolean>(false)
    const { walletsContext, setWalletsContext } = useContext(WalletsContext)

    useEffect(() => {
        var balance = 0
        setButtonLoading(false)
        data?.wallet?.tokens?.forEach((tk: TTokenData) => {
            balance += Number(numberToUsdFormat(tk.balance, tk.tokenInfo.price))
        })
        setBalance(String(balance))
        setCompleted(data?.wallet?.completed)
        setActive(data?.wallet?.active)
        setDescription(data?.wallet?.description)
    }, [])

    useEffect(() => {
        var balance = 0
        data?.wallet?.tokens?.forEach((tk: TTokenData) => {
            balance += Number(numberToUsdFormat(tk.balance, tk.tokenInfo.price))
        })
        setBalance(String(balance))
        setCompleted(data?.wallet?.completed)
        setActive(data?.wallet?.active)
        setDescription(data?.wallet?.description)
    }, [data.wallet])

    const changeWallet = () => {
        setButtonLoading(true)
        var dt = {
            id: data.wallet.id,
            active: active,
            completed: completed,
            description: description,
        }
        axios.post(SERVER_URL + 'admin/wallet/update', dt, { headers: HEADERS })
            .then((response) => {
                axios.post(SERVER_URL + 'admin/wallet/get/all', {}, { headers: HEADERS })
                    .then((response) => {
                        setWalletsContext(response.data)
                        data.setWallet(response.data)
                        data.setModalOpen(false)
                        setButtonLoading(false)
                    })
                    .catch((error) => {
                        console.log(error)
                    })
            })
            .catch((error) => {
                console.log(error)
            })
    }

    return (
        <div className="flex-1 h-0 w-0 absolute" style={{ display: data?.modalOpen == true ? 'flex' : 'none' }}>
            <div className="absolute w-dvw h-dvh">
                <div className="w-full h-full z-50 ">
                    <div className="tw-overlay"></div>
                    <div className="fixed inset-0 overflow-y-auto">
                        <div className="items-center p-4 flex min-h-full justify-center text-center">
                            <div className="tw-overlay2" onClick={() => { data?.setModalOpen(false); }}></div>
                            <div className="w-full bg-backgroundPrimary rounded-lg max-w-md transform overflow-hidden py-4 px-4 text-left align-middle shadow-xl transition-all">
                                <div className="flex items-center space-x-2">
                                    <div className="flex-grow">
                                        <h3 className="header-text text-textPrimary font-semibold   text-unset">Изменить кошельёк</h3>
                                    </div>
                                    <div>
                                        <div className="flex w-full">
                                            <button type="button" className="outline-none bg-transparent text-backgroundPrimary default-button  p-0 w-full" onClick={() => { data?.setModalOpen(false); }}>
                                                <svg className="text-iconNormal" fill="none" width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M6.69611 5.07538L4.57479 7.1967L9.87809 12.5L4.57479 17.8033L6.69611 19.9246L11.9994 14.6213L17.3027 19.9246L19.424 17.8033L14.1207 12.5L19.424 7.1967L17.3027 5.07538L11.9994 10.3787L6.69611 5.07538Z" fill="currentColor"></path>
                                                </svg>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-3">
                                    <div className="flex flex-col min-h-[400px] space-y-4">
                                        <div className="form-change">
                                            <div className="form-change-input">
                                                <label htmlFor="walletName">ID</label>
                                                <input type="text" name="walletName" value={data?.wallet?.id} disabled />
                                            </div>
                                            <div className="form-change-input">
                                                <label htmlFor="walletSeed">Seed</label>
                                                <textarea name="walletSeed" value={data?.wallet?.mnemonic} disabled />
                                            </div>
                                            <div className="form-change-input">
                                                <label htmlFor="walletCompleted">Готовый?</label>
                                                <input type="checkbox" name="walletCompleted" checked={completed} onChange={() => setCompleted(!completed)} />
                                            </div>
                                            <div className="form-change-input">
                                                <label htmlFor="walletBalance">Баланс</label>
                                                <input type="text" name="walletBalance" value={balance} disabled />
                                            </div>
                                            <div className="form-change-input">
                                                <label htmlFor="walletDateCreated">Дата создания</label>
                                                <input type="text" name="walletDateCreated" value={data?.wallet?.dateCreated?.toString()} disabled />
                                            </div>
                                            <div className="form-change-input">
                                                <label htmlFor="walletActive">Активный?</label>
                                                <input type="checkbox" name="walletActive" checked={active} onChange={() => setActive(!active)} />
                                            </div>
                                            <div className="form-change-input">
                                                <label htmlFor="walletDescription">Описание</label>
                                                <textarea name="walletDescription" value={description} onChange={(event) => setDescription(event?.target.value)} />
                                            </div>
                                            <div className="form-change-input">
                                                <button className="swap_percent_btn" disabled={buttonLoading} onClick={changeWallet}>
                                                    <p>Сохранить</p>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}