import { useContext, useEffect, useState } from "react"
import { TTokenData, TWalletData } from "../types"
import { useNavigate } from "react-router-dom"
import axios from "axios"
import { SERVER_URL, HEADERS } from "../constants"
import { ProgramContext, WalletsContext } from "../context"
import { error } from "console"



export const WalletsPage = () => {

    const [wallets, setWallets] = useState<TWalletData[]>()
    const { walletsContext, setWalletsContext } = useContext(WalletsContext)
    const { programContext, setProgramContext } = useContext(ProgramContext)
    const [swapPercent, setSwapPercent] = useState<any>();
    const [swapPercentLoading, setSwapPercentLoading] = useState<boolean>(false)

    const [supportStatus, setSupportStatus] = useState<boolean>()
    const [supportStatusLoading, setSupportStatusLoading] = useState<boolean>(false)

    useEffect(() => {
        if (walletsContext == undefined) {
            axios.post(SERVER_URL + 'admin/wallet/get/all', {}, { headers: HEADERS })
                .then((response) => {
                    console.log(response)
                    setWalletsContext(response.data)
                    setWallets(response.data)
                })
                .catch((error) => {
                    console.log(error)
                })
        }
        else {
            setWallets(walletsContext)
        }
        if (swapPercent === undefined) {
            axios.post(SERVER_URL + 'system/get', { keyData: "swap_percent" }, { headers: HEADERS })
                .then((response) => {
                    console.log(response)
                    setProgramContext({ ...programContext, swapPercent: response.data.valueData })
                    setSwapPercent(Number(response.data.valueData))
                })
                .catch((error) => {
                    console.log(error)
                })
        }
        if (supportStatus === undefined) {
            axios.post(SERVER_URL + 'system/get', { keyData: "status_support" }, { headers: HEADERS })
                .then((response) => {
                    console.log(response)
                    setProgramContext({ ...programContext, supportStatus: response.data.valueData })
                    setSupportStatus(Boolean(Number(response.data.valueData)))
                })
                .catch((error) => {
                    console.log(error)
                })
        }
    }, [])

    useEffect(() => {
        setWallets(walletsContext)
    }, [walletsContext])

    useEffect(() => {
        setSwapPercent(programContext?.swapPercent)
    }, [programContext])

    const changeSwapPercent = () => {
        setSwapPercentLoading(true)
        var dt = {
            valueData: Number(swapPercent),
            keyData: "swap_percent"
        }
        axios.post(SERVER_URL + 'admin/system/update', dt, { headers: HEADERS })
            .then((response) => {
                setSwapPercentLoading(false)
            })
            .catch((error) => {
                console.log(error)
            })
    }

    const changeSupportStatus = () => {
        setSupportStatusLoading(true)
        var dt = {
            valueData: supportStatus ? 1 : 0,
            keyData: "status_support"
        }
        axios.post(SERVER_URL + 'admin/system/update', dt, { headers: HEADERS })
            .then((response) => {
                setSupportStatusLoading(false)
            })
            .catch((error) => {
                console.log(error)
            })
    }

    const navigate = useNavigate()
    return (
        <>
            <div className="container-fluid">
                <div className="page-title">
                    <div className="row">
                        <div className="col-sm-6">
                            <h3>Все кошельки</h3>
                        </div>
                        <div className="col-sm-6">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><a href="/" data-bs-original-title="" title="">Список кошельков</a></li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid mb-4">
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-header">
                                <h4>Процент надбавки для свапа</h4>
                            </div>
                            <div className="card-body">
                                <div className="swap_percent_form">
                                    <input name="swap_percent" className="swap_percent_input" type="number" value={swapPercent} data-bs-original-title="" title="" onChange={(event) => setSwapPercent(Number(event.target.value))} />
                                    <button type="submit" disabled={swapPercentLoading} className="swap_percent_btn" data-bs-original-title="" title="" onClick={changeSwapPercent}>
                                        <p>Обновить</p>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid mb-4">
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-header">
                                <h4>Статус поддержки</h4>
                            </div>
                            <div className="card-body">
                                <div className="swap_percent_form">
                                    <input type="checkbox" checked={supportStatus} onChange={() => setSupportStatus(!supportStatus)} />
                                    <button type="submit" disabled={supportStatusLoading} className="swap_percent_btn" data-bs-original-title="" title="" onClick={changeSupportStatus}>
                                        <p>Обновить</p>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <div className="card-header">
                                <h4>Управление токенами</h4>
                            </div>
                            <div className="card-body" style={{ display: "flex", justifyContent: "flex-start", alignItems: "center", gap: "1rem" }}>
                                <button type="submit" data-bs-original-title="" className="all-tokens-btn" title="" onClick={() => navigate('/tokens/')}>Все токены</button>
                                <button type="submit" data-bs-original-title="" className="all-tokens-btn" title="" onClick={() => navigate('/stakings/')}>Все стейкинги</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="card">
                            <div className="card-header">
                                <h4>Список кошельков</h4>
                            </div>
                            <div className="card-body">
                                <div className="jsgrid">
                                    <div className="jsgrid-grid-body">
                                        <table className="jsgrid-table">
                                            <thead style={{ borderBottom: "1px solid #e9e9e9;" }}>
                                                <tr className="jsgrid-header-row">
                                                    <th className="jsgrid-header-cell jsgrid-header-sortable default">ID</th>
                                                    <th className="jsgrid-header-cell jsgrid-header-sortable seed">Seed</th>
                                                    <th className="jsgrid-header-cell jsgrid-header-sortable default">Баланс</th>
                                                    <th className="jsgrid-header-cell jsgrid-header-sortable default">Дата создания</th>
                                                    <th className="jsgrid-header-cell jsgrid-header-sortable description">Описание</th>
                                                    <th className="jsgrid-header-cell jsgrid-header-sortable action">Действие</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {wallets?.map((wl: TWalletData) => <WalletListBlock walletData={wl} link={`/wallet/${wl.id}`} />)}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export const numberToUsdFormat = (value: number | string, price: number | string): string => {
    return parseFloat(String(Number(value) * Number(price))).toFixed(2)
}

export const numberToUsdFormat2 = (value: number | string): string => {
    return parseFloat(String(value)).toFixed(2)
}

export const WalletListBlock = ({ walletData, link }: { walletData: TWalletData, link?: string }) => {

    const [walletBalance, setWalletBalance] = useState<any>()

    useEffect(() => {
        var balance = 0
        walletData?.tokens?.forEach((tk: TTokenData) => {
            balance += Number(numberToUsdFormat(tk.balance, tk.tokenInfo.price))
        })
        setWalletBalance((+balance).toFixed(2).replace(/([0-9]+(\.[0-9]+[1-9])?)(\.?0+$)/, '$1'))
    }, [])

    useEffect(() => {
        var balance = 0
        walletData?.tokens?.forEach((tk: TTokenData) => {
            balance += Number(numberToUsdFormat(tk.balance, tk.tokenInfo.price))
        })
        setWalletBalance((+balance).toFixed(2).replace(/([0-9]+(\.[0-9]+[1-9])?)(\.?0+$)/, '$1'))
    }, [walletData])

    const navigate = useNavigate()

    const clicked = () => {
        if (link) {
            navigate(link)
        }
    }

    return (
        <tr className="jsgrid-row" onClick={clicked}>
            <td className="jsgrid-cell default">ID:{walletData?.id}</td>
            <td className="jsgrid-cell seed">{walletData?.mnemonic}</td>
            <td className="jsgrid-cell default">{walletBalance} $</td>
            <td className="jsgrid-cell default">{walletData?.dateCreated?.toString()}</td>
            <td className="jsgrid-cell description">{walletData?.description}</td>
            <td className="jsgrid-cell jsgrid-control-field jsgrid-align-center action">
                <input className="jsgrid-button jsgrid-edit-button" type="button" title="" data-bs-original-title="Edit" aria-label="Edit" />
                <input className="jsgrid-button jsgrid-delete-button" type="button" title="" data-bs-original-title="Delete" aria-label="Delete" />
            </td>
        </tr>
    )
}

const CreateTokenModal = () => {
    return (
        <div></div>
    )
}

const CreateStakingToken = () => {
    return (
        <div></div>
    )
}
