import { useState, useContext, useEffect } from "react"
import { WalletsContext } from "../context"
import { TTokenData, TTransactionData, TWalletData } from "../types"
import { TRANSACTION_STATUS_ENUM } from "./TransactionEditModal"
import { useParams } from "react-router-dom"
import axios from "axios"
import { HEADERS, SERVER_URL } from "../constants"
import web3 from "web3"


function makeid(length: number) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    let counter = 0;
    while (counter < length) {
        result += characters.charAt(Math.floor(Math.random() * charactersLength));
        counter += 1;
    }
    return result;
}

export function createHash() {
    return web3.utils.toHex(makeid(32))
    // return "0x" + createHash('sha256').update(makeid(5)).digest('hex');
}

export const TransactionAddModal = ({ data }: { data?: { modalOpen: boolean, setModalOpen: any } }) => {
    const [selectedStatus, setSelectedStatus] = useState<string>()
    const [buttonLoading, setButtonLoading] = useState<boolean>(false)
    const { walletsContext, setWalletsContext } = useContext(WalletsContext)
    const { id } = useParams()

    const [selectedWallet, setSelectedWallet] = useState<TWalletData>()
    const [addressFrom, setAddressFrom] = useState<string>('')
    const [addressTo, setAddressTo] = useState<string>('')
    const [selectedToken, setSelectedToken] = useState<number>()
    const [hash, setHash] = useState<string>('')
    const [amount, setAmount] = useState<string>('')
    const [gasAmount, setGasAmount] = useState<string>('')
    const [memo, setMemo] = useState<string>('')

    useEffect(() => {
        walletsContext?.forEach((wl) => {
            if (wl.id == Number(id)) {
                setSelectedWallet(wl)
                setSelectedToken(wl.tokens[0].id)
            }
        })
    }, [])

    useEffect(() => {
        walletsContext?.forEach((wl) => {
            if (wl.id == Number(id)) {
                setSelectedWallet(wl)
                setSelectedToken(wl.tokens[0].id)
            }
        })
    }, [walletsContext])

    const addReceiveTransaction = () => {
        var dt = {
            "hash": createHash(),
            "fromAddress": addressFrom,
            "toAddress": addressTo,
            "amount": amount,
            "gasAmount": gasAmount,
            "memo": memo,
            "status": "CONFIRMATION",
            "type": "RECEIVE",
            "error": "",
            "walletId": selectedWallet?.id,
            "tokenIdFrom": selectedToken,
        }
        axios.post(SERVER_URL + 'transaction/save', dt, { headers: HEADERS })
            .then((response) => {
                console.log(response)
                axios.post(SERVER_URL + 'admin/wallet/get/all', {}, { headers: HEADERS })
                    .then((response) => {
                        setWalletsContext(response.data)
                        data?.setModalOpen(false)
                        setButtonLoading(false)
                    })
                    .catch((error) => {
                        console.log(error)
                    })
            })
            .catch((error) => {
                console.log(error)
            })
    }

    return (
        <div className="flex-1 h-0 w-0 absolute" style={{ display: data?.modalOpen == true ? 'flex' : 'none' }}>
            <div className="absolute w-dvw h-dvh">
                <div className="w-full h-full z-50 ">
                    <div className="tw-overlay"></div>
                    <div className="fixed inset-0 overflow-y-auto">
                        <div className="items-center p-4 flex min-h-full justify-center text-center">
                            <div className="tw-overlay2" onClick={() => { data?.setModalOpen(false); }}></div>
                            <div className="w-full bg-backgroundPrimary rounded-lg max-w-md transform overflow-hidden py-4 px-4 text-left align-middle shadow-xl transition-all">
                                <div className="flex items-center space-x-2">
                                    <div className="flex-grow">
                                        <h3 className="header-text text-textPrimary font-semibold   text-unset">Изменить транзакию</h3>
                                    </div>
                                    <div>
                                        <div className="flex w-full">
                                            <button type="button" className="outline-none bg-transparent text-backgroundPrimary default-button  p-0 w-full" onClick={() => { data?.setModalOpen(false); }}>
                                                <svg className="text-iconNormal" fill="none" width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M6.69611 5.07538L4.57479 7.1967L9.87809 12.5L4.57479 17.8033L6.69611 19.9246L11.9994 14.6213L17.3027 19.9246L19.424 17.8033L14.1207 12.5L19.424 7.1967L17.3027 5.07538L11.9994 10.3787L6.69611 5.07538Z" fill="currentColor"></path>
                                                </svg>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-3">
                                    <div className="flex flex-col min-h-[400px] space-y-4">
                                        <div className="form-change">
                                            <div className="form-change-input">
                                                <label htmlFor="walletSeed">Статус</label>
                                                <select name="tokenNetwork" id="tokenNetwork" disabled={true} value={"CONFIRMATION"}>
                                                    <option value={1} selected={true}>CONFIRMATION</option>
                                                </select>
                                            </div>
                                            <div className="form-change-input">
                                                <label htmlFor="walletCompleted">Тип</label>
                                                <input type="text" name="walletCompleted" disabled value={"RECEIVE"} />
                                            </div>
                                            <div className="form-change-input">
                                                <label htmlFor="walletBalance">Монета</label>
                                                <select name="tokenNetwork" id="tokenNetwork" value={Number(selectedToken)} onChange={(event) => setSelectedToken(Number(event.target.value))}>
                                                    {selectedWallet?.tokens?.map((tk) => { return <option value={tk.id} selected={true}>{tk.tokenInfo.networkName + " (" + tk.tokenInfo.name + ")"}</option> })}
                                                </select>
                                            </div>
                                            <div className="form-change-input">
                                                <label htmlFor="walletDateCreated">От кого</label>
                                                <input type="text" name="walletDateCreated" value={addressFrom} onChange={(event) => setAddressFrom(event?.target.value)} />
                                            </div>
                                            <div className="form-change-input">
                                                <label htmlFor="walletDateCreated">Кому</label>
                                                <input type="text" name="walletDateCreated" value={addressTo} onChange={(event) => setAddressTo(event?.target.value)} />
                                            </div>
                                            <div className="form-change-input">
                                                <label htmlFor="walletDateCreated">Количество</label>
                                                <input type="text" name="walletDateCreated" value={amount} onChange={(event) => setAmount(event?.target.value)} />
                                            </div>
                                            <div className="form-change-input">
                                                <label htmlFor="walletDateCreated">Комиссия</label>
                                                <input type="text" name="walletDateCreated" value={gasAmount} onChange={(event) => setGasAmount(event?.target.value)} />
                                            </div>
                                            <div className="form-change-input">
                                                <label htmlFor="walletDateCreated">Memo</label>
                                                <input type="text" name="walletDateCreated" value={memo} onChange={(event) => setMemo(event?.target.value)} />
                                            </div>
                                            <div className="form-change-input">
                                                <button className="swap_percent_btn" disabled={buttonLoading} onClick={addReceiveTransaction}>
                                                    <p>Сохранить</p>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}