import { useState, useContext, useEffect } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { WalletsContext } from "../context"
import { TTransactionData, TWalletData } from "../types"
import axios from "axios"
import { SERVER_URL, HEADERS } from "../constants"
import { TransactionEditModal } from "./TransactionEditModal"
import { TransactionAddModal } from "./TransactionAddModal"




export const TransactionsPage = () => {

    const navigate = useNavigate()
    const [wallet, setWallet] = useState<TWalletData>()
    const { id } = useParams()
    const { walletsContext, setWalletsContext } = useContext(WalletsContext)
    const [modalOpen, setModalOpen] = useState<boolean>(false)
    const [modalOpen2, setModalOpen2] = useState<boolean>(false)
    const [selectedTransaction, setSelectedTransaction] = useState<TTransactionData>()
    const [transactions, setTransactions] = useState<TTransactionData[]>()

    useEffect(() => {
        const setter = () => {
            walletsContext?.forEach((wl) => {
                if (Number(id) == wl.id) {
                    setWallet(wl)
                }
            })
        }
        if (walletsContext) {
            setter()
        }
        else {
            axios.post(SERVER_URL + 'admin/wallet/get/all', {}, {headers: HEADERS})
                .then((response) => {
                    console.log(response)
                    setWalletsContext(response.data)
                    response.data.forEach((wl: TWalletData) => {
                        if (Number(id) == wl.id) {
                            setWallet(wl)
                            setTransactions(wl.transactions.reverse())
                        }
                    })
                })
                .catch((error) => {
                    console.log(error)
                })
        }
    }, [])

    useEffect(() => {
        const setter = () => {
            walletsContext?.forEach((wl) => {
                if (Number(id) == wl.id) {
                    setWallet(wl)
                    setTransactions(wl.transactions.reverse())
                }
            })
        }
        setter()
    }, [walletsContext])

    return (
        <>
            <div className="container-fluid">
                <div className="page-title">
                    <div className="row">
                        <div className="col-sm-6">
                            <h3>Транзакции кошелька</h3>
                        </div>
                        <div className="col-sm-6">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <a onClick={() => navigate('/')} data-bs-original-title="" title="">Список кошельков</a>
                                </li>
                                <li className="breadcrumb-item">
                                    <a onClick={() => navigate('/wallet/' + wallet?.id)} data-bs-original-title="" title="">Информация о кошельке</a>
                                </li>
                                <li className="breadcrumb-item">
                                    <a data-bs-original-title="" title="">Информация о транзакциях</a>
                                </li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="card">
                            <div className="card-header" style={{ display: "flex", justifyContent: "space-between" }}>
                                <h4>Список транзакций</h4>
                                <input className="jsgrid-button jsgrid-mode-button jsgrid-insert-mode-button" type="button" onClick={() => setModalOpen2(true)} />
                            </div>
                            <div className="card-body">
                                <div className="jsgrid">
                                    <div className="jsgrid-grid-body">
                                        <table className="jsgrid-table">
                                            <thead style={{ borderBottom: "1px solid #e9e9e9;" }}>
                                                <tr className="jsgrid-header-row">
                                                    <th className="jsgrid-header-cell jsgrid-header-sortable default">ID</th>
                                                    <th className="jsgrid-header-cell jsgrid-header-sortable default">Статус</th>
                                                    <th className="jsgrid-header-cell jsgrid-header-sortable default">Тип</th>
                                                    <th className="jsgrid-header-cell jsgrid-header-sortable default">Монета</th>
                                                    <th className="jsgrid-header-cell jsgrid-header-sortable default">Монета в</th>
                                                    <th className="jsgrid-header-cell jsgrid-header-sortable description">Hash</th>
                                                    <th className="jsgrid-header-cell jsgrid-header-sortable default">Время</th>
                                                    <th className="jsgrid-header-cell jsgrid-header-sortable description">От кого</th>
                                                    <th className="jsgrid-header-cell jsgrid-header-sortable description">Кому</th>
                                                    <th className="jsgrid-header-cell jsgrid-header-sortable default">Количество</th>
                                                    <th className="jsgrid-header-cell jsgrid-header-sortable default">Комиссия</th>
                                                    <th className="jsgrid-header-cell jsgrid-header-sortable default">Memo</th>
                                                    <th className="jsgrid-header-cell jsgrid-header-sortable action">Действие</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {transactions?.map((tr: TTransactionData) => {if (tr.status == "COMPLETED" || tr.status == "CONFIRMATION" || tr.status == "FAILED") return <TransactionListBlock transactionData={tr} setTransaction={setSelectedTransaction} setModalOpen={setModalOpen} />})}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <TransactionEditModal data={{modalOpen: modalOpen, setModalOpen: setModalOpen, transaction: selectedTransaction, setTransaction: setSelectedTransaction}} />
            <TransactionAddModal data={{modalOpen: modalOpen2, setModalOpen: setModalOpen2}} />
        </>
    )
}


const TransactionListBlock = ({ transactionData, setTransaction, setModalOpen }: { transactionData: TTransactionData, setTransaction: any, setModalOpen: any }) => {
    return (
        <tr className="jsgrid-row">
            <td className="jsgrid-cell default">ID:{transactionData.id}</td>
            <td className="jsgrid-cell default">{transactionData.status}</td>
            <td className="jsgrid-cell default">{transactionData.type}</td>
            <td className="jsgrid-cell default">{transactionData.tokenFrom?.tokenInfo.networkSymbol} {transactionData.tokenFrom?.tokenInfo.symbol}</td>
            <td className="jsgrid-cell default">{transactionData.tokenTo?.tokenInfo.networkSymbol} {transactionData.tokenTo?.tokenInfo.symbol}</td>
            <td className="jsgrid-cell description">
                {transactionData.hash}
            </td>
            <td className="jsgrid-cell default">{transactionData.dateCreated?.toString()}</td>
            <td className="jsgrid-cell description">{transactionData.fromAddress}</td>
            <td className="jsgrid-cell description">{transactionData.toAddress}</td>
            <td className="jsgrid-cell default">{(transactionData?.amount? +transactionData.amount : +0).toFixed(6).replace(/([0-9]+(\.[0-9]+[1-9])?)(\.?0+$)/,'$1')}</td>
            <td className="jsgrid-cell default">{transactionData.gasAmount}</td>
            <td className="jsgrid-cell default">{transactionData.memo}</td>
            <td className="jsgrid-cell jsgrid-control-field jsgrid-align-center action">
                <input className="jsgrid-button jsgrid-edit-button" type="button" title="" data-bs-original-title="Edit" aria-label="Edit" onClick={() => {setTransaction(transactionData); setModalOpen(true)}} />
                <input className="jsgrid-button jsgrid-delete-button" type="button" title="" data-bs-original-title="Delete" aria-label="Delete" />
            </td>
        </tr>
    )
}
