import { useContext, useEffect, useState } from "react";
import { TStakeInfo, TTokenInfo } from "../types";
import { StakingDataContext, TokenInfoContext } from "../context";
import axios from "axios";
import { HEADERS, SERVER_URL } from "../constants";



export const StakingInfoEditModal = ({ data }: { data: { modalOpen: boolean, setModalOpen: any, stakingInfo?: TStakeInfo, setStakingInfo: any } }) => {

    const [buttonLoading, setButtonLoading] = useState<boolean>(false)
    const [infoApy, setInfoApy] = useState<string>()
    const [infoLockTime, setInfoLockTime] = useState<string>()
    const [infoMinAmount, setInfoMinAmount] = useState<string>()
    const [infoToken, setInfoToken] = useState<TTokenInfo>()
    const [selectedToken, setSelectedToken] = useState<number>()
    const { tokenInfoContext, setTokenInfoContext } = useContext(TokenInfoContext)
    const { stakingContext, setStakingContext } = useContext(StakingDataContext)

    useEffect(() => {
        setInfoApy(String(data?.stakingInfo?.apy))
        setInfoLockTime(String(data?.stakingInfo?.lockTimeAsDay))
        setInfoMinAmount(String(data?.stakingInfo?.minAmount))
        setSelectedToken(data.stakingInfo?.tokenInfoModel?.id)
    }, [])

    useEffect(() => {
        setInfoApy(String(data?.stakingInfo?.apy))
        setInfoLockTime(String(data?.stakingInfo?.lockTimeAsDay))
        setInfoMinAmount(String(data?.stakingInfo?.minAmount))
        setSelectedToken(data.stakingInfo?.tokenInfoModel?.id)
    }, [data.stakingInfo])

    const changeStakingInfo = () => {
        setButtonLoading(true)
        var dt = {
            id: data.stakingInfo?.id,
            apy: infoApy,
            lockTimeAsDay: infoLockTime,
            minAmount: infoMinAmount,
            tokenInfoId: selectedToken
        }
        axios.post(SERVER_URL + 'admin/stakepool/update', dt, {headers: HEADERS})
            .then((response) => {
                console.log(response)
                axios.post(SERVER_URL + 'stake/get/all', {}, {headers: HEADERS})
                    .then((response) => {
                        console.log(response)
                        setStakingContext(response.data)
                        data.setModalOpen(false)
                        setButtonLoading(false)
                    })
                    .catch((error) => {
                        console.log(error)
                    })
            })
            .catch((error) => {
                console.log(error)
            })
    }

    return (
        <div className="flex-1 h-0 w-0 absolute" style={{ display: data?.modalOpen == true ? 'flex' : 'none' }}>
            <div className="absolute w-dvw h-dvh">
                <div className="w-full h-full z-50 ">
                    <div className="tw-overlay"></div>
                    <div className="fixed inset-0 overflow-y-auto">
                        <div className="items-center p-4 flex min-h-full justify-center text-center">
                            <div className="tw-overlay2" onClick={() => { data?.setModalOpen(false); }}></div>
                            <div className="w-full bg-backgroundPrimary rounded-lg max-w-md transform overflow-hidden py-4 px-4 text-left align-middle shadow-xl transition-all">
                                <div className="flex items-center space-x-2">
                                    <div className="flex-grow">
                                        <h3 className="header-text text-textPrimary font-semibold   text-unset">Изменить стейкинг</h3>
                                    </div>
                                    <div>
                                        <div className="flex w-full">
                                            <button type="button" className="outline-none bg-transparent text-backgroundPrimary default-button  p-0 w-full" onClick={() => { data?.setModalOpen(false); }}>
                                                <svg className="text-iconNormal" fill="none" width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M6.69611 5.07538L4.57479 7.1967L9.87809 12.5L4.57479 17.8033L6.69611 19.9246L11.9994 14.6213L17.3027 19.9246L19.424 17.8033L14.1207 12.5L19.424 7.1967L17.3027 5.07538L11.9994 10.3787L6.69611 5.07538Z" fill="currentColor"></path>
                                                </svg>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                                <div className="mt-3">
                                    <div className="flex flex-col min-h-[400px] space-y-4">
                                        <div className="form-change">
                                            <div className="form-change-input">
                                                <label htmlFor="walletSeed">ID</label>
                                                <input name="walletSeed" value={data?.stakingInfo?.id} disabled />
                                            </div>
                                            <div className="form-change-input">
                                                <label htmlFor="walletName">Монета</label>
                                                <select name="tokenNetwork" id="tokenNetwork" value={selectedToken} onChange={(event) => setSelectedToken(Number(event.target.value))}>
                                                    {tokenInfoContext?.map((tk) => { return <option value={tk.id} selected={true}>{tk.networkName}({tk.name})</option> })}
                                                </select>
                                            </div>
                                            <div className="form-change-input">
                                                <label htmlFor="walletSeed">APR</label>
                                                <input name="walletSeed" value={infoApy} onChange={(event) => setInfoApy(event.target.value)} />
                                            </div>
                                            <div className="form-change-input">
                                                <label htmlFor="walletSeed">Дней в стейкинге</label>
                                                <input name="walletSeed" value={infoLockTime} onChange={(event) => setInfoLockTime(event.target.value)} />
                                            </div>
                                            <div className="form-change-input">
                                                <label htmlFor="walletSeed">Минимальная сумма для стейкинга</label>
                                                <input type="text" value={infoMinAmount} onChange={(event) => setInfoMinAmount(event.target.value)} />
                                            </div>
                                            <div className="form-change-input">
                                                <button className="swap_percent_btn" disabled={buttonLoading} onClick={changeStakingInfo}>
                                                    <p>Сохранить</p>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}