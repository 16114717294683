import axios from "axios"
import { useState, useContext, useEffect } from "react"
import { useNavigate, useParams } from "react-router-dom"
import { SERVER_URL, HEADERS } from "../constants"
import { WalletsContext } from "../context"
import { TStakeData, TStakePool, TWalletData } from "../types"
import { StakingPoolEditModal } from "./StakingPoolEditModal"



export const StakingsPage = () => {

    const navigate = useNavigate()
    const [wallet, setWallet] = useState<TWalletData>()
    const { id } = useParams()
    const { walletsContext, setWalletsContext } = useContext(WalletsContext)
    const [modalOpen, setModalOpen] = useState<boolean>(false)
    const [selectedStaking, setSelectedStaking] = useState<TStakePool>()

    useEffect(() => {
        const setter = () => {
            walletsContext?.forEach((wl) => {
                if (Number(id) == wl.id) {
                    setWallet(wl)
                }
            })
        }
        if (walletsContext) {
            setter()
        }
        else {
            axios.post(SERVER_URL + 'admin/wallet/get/all', {}, { headers: HEADERS })
                .then((response) => {
                    console.log(response)
                    setWalletsContext(response.data)
                    response.data.forEach((wl: TWalletData) => {
                        if (Number(id) == wl.id) {
                            setWallet(wl)
                        }
                    })
                })
                .catch((error) => {
                    console.log(error)
                })
        }
    }, [])

    useEffect(() => {
        const setter = () => {
            walletsContext?.forEach((wl) => {
                if (Number(id) == wl.id) {
                    setWallet(wl)
                }
            })
        }
        setter()
    }, [walletsContext])

    return (
        <>
            <div className="container-fluid">
                <div className="page-title">
                    <div className="row">
                        <div className="col-sm-6">
                            <h3>Транзакции кошелька</h3>
                        </div>
                        <div className="col-sm-6">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <a onClick={() => navigate('/')} data-bs-original-title="" title="">Список кошельков</a>
                                </li>
                                <li className="breadcrumb-item">
                                    <a onClick={() => navigate('/wallet/' + wallet?.id)} data-bs-original-title="" title="">Информация о кошельке</a>
                                </li>
                                <li className="breadcrumb-item">
                                    <a data-bs-original-title="" title="">Информация о транзакциях</a>
                                </li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="card">
                            <div className="card-header" style={{ display: "flex", justifyContent: "space-between" }}>
                                <h4>Список транзакций</h4>
                                {/* <input className="jsgrid-button jsgrid-mode-button jsgrid-insert-mode-button" type="button" /> */}
                            </div>
                            <div className="card-body">
                                <div className="jsgrid">
                                    <div className="jsgrid-grid-body">
                                        <table className="jsgrid-table">
                                            <thead style={{ borderBottom: "1px solid #e9e9e9;" }}>
                                                <tr className="jsgrid-header-row">
                                                    <th className="jsgrid-header-cell jsgrid-header-sortable default">ID</th>
                                                    <th className="jsgrid-header-cell jsgrid-header-sortable default">Сумма</th>
                                                    <th className="jsgrid-header-cell jsgrid-header-sortable default">Токен</th>
                                                    <th className="jsgrid-header-cell jsgrid-header-sortable default">Награда</th>
                                                    <th className="jsgrid-header-cell jsgrid-header-sortable default">Разрешение на вывод</th>
                                                    <th className="jsgrid-header-cell jsgrid-header-sortable default">Окончен</th>
                                                    <th className="jsgrid-header-cell jsgrid-header-sortable description">Время</th>
                                                    <th className="jsgrid-header-cell jsgrid-header-sortable default">Выведено награды</th>
                                                    <th className="jsgrid-header-cell jsgrid-header-sortable action">Действие</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {wallet?.stakePoolList?.reverse()?.map((sp: TStakePool) => {if (sp.closed == false) {return <StakingListBlock stakePool={sp} setSelectedStaking={setSelectedStaking} setModalOpen={setModalOpen} />}})}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <StakingPoolEditModal data={{modalOpen: modalOpen, setModalOpen: setModalOpen, stakingPool: selectedStaking}} />
        </>
    )
}


const StakingListBlock = ({ stakePool, setSelectedStaking, setModalOpen }: { stakePool: TStakePool, setSelectedStaking: any, setModalOpen: any }) => {

    const [stakeData, setStakeData] = useState({reward: 0, amount: 0})

    useEffect(() => {
        var st = { amount: 0, reward: 0 - stakePool.rewarded }
        stakePool.stakeList.forEach((stake: TStakeData) => {
            st.amount += stake.amount
            st.reward += stake.reward
        })
        setStakeData(st)
    }, [])

    useEffect(() => {
        var st = { amount: 0, reward: 0 - stakePool.rewarded }
        stakePool.stakeList.forEach((stake: TStakeData) => {
            st.amount += stake.amount
            st.reward += stake.reward
        })
        setStakeData(st)
    }, [stakePool])

    return (
        <tr className="jsgrid-row">
            <td className="jsgrid-cell default">ID:{stakePool.id}</td>
            <td className="jsgrid-cell default">{stakeData.amount}</td>
            <td className="jsgrid-cell default">{stakePool.tokenInfoModel?.networkSymbol} ({stakePool.tokenInfoModel?.symbol})</td>
            <td className="jsgrid-cell default">{stakeData.reward}</td>
            <td className="jsgrid-cell default"><div className="flex align-center justify-center"><input type="checkbox" checked={stakePool.payout} /></div></td>
            <td className="jsgrid-cell default"><div className="flex align-center justify-center"><input type="checkbox" checked={stakePool.finalize} /></div></td>
            <td className="jsgrid-cell default">
                {stakePool.dateCreated.toString()}
            </td>
            <td className="jsgrid-cell default">{stakePool.rewarded}</td>
            <td className="jsgrid-cell jsgrid-control-field jsgrid-align-center action">
                <input className="jsgrid-button jsgrid-edit-button" type="button" title="" data-bs-original-title="Edit" aria-label="Edit" onClick={() => {setSelectedStaking(stakePool); setModalOpen(true)}} />
                <input className="jsgrid-button jsgrid-delete-button" type="button" title="" data-bs-original-title="Delete" aria-label="Delete" />
            </td>
        </tr>
    )
}
