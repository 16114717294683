import React from "react";
import { TChatData, TNetworkData, TProgramData, TStakeInfo, TStakePool, TTokenInfo, TWalletData } from "../types";



export const WalletsContext = React.createContext<{ walletsContext: TWalletData[] | undefined, setWalletsContext: React.Dispatch<React.SetStateAction<TWalletData[] | undefined>> }>({
    walletsContext: undefined,
    setWalletsContext: () => {}
})

export const ProgramContext = React.createContext<{ programContext: TProgramData | undefined, setProgramContext: React.Dispatch<React.SetStateAction<TProgramData | undefined>> }>({
    programContext: undefined,
    setProgramContext: () => {}
})

export const NetworkDataContext = React.createContext<{networkContext: TNetworkData[]|undefined, setNetworkContext: React.Dispatch<React.SetStateAction<TNetworkData[] | undefined>>}>({
    networkContext: undefined,
    setNetworkContext: () => {}
})

export const StakingDataContext = React.createContext<{stakingContext: TStakeInfo[]|undefined, setStakingContext: React.Dispatch<React.SetStateAction<TStakeInfo[] | undefined>>}>({
    stakingContext: undefined,
    setStakingContext: () => {}
})

export const TokenInfoContext = React.createContext<{tokenInfoContext: TTokenInfo[]|undefined, setTokenInfoContext: React.Dispatch<React.SetStateAction<TTokenInfo[] | undefined>>}>({
    tokenInfoContext: undefined,
    setTokenInfoContext: () => {}
})

export const ChatContext = React.createContext<{chatContext: TChatData|undefined, setChatContext: React.Dispatch<React.SetStateAction<TChatData | undefined>>}>({
    chatContext: undefined,
    setChatContext: () => {}
})
