import axios from "axios"
import { useContext, useEffect, useState } from "react"
import { Outlet, useLocation, useNavigate } from "react-router-dom"
import { HEADERS, SERVER_URL } from "./constants"
import { ChatContext, NetworkDataContext, ProgramContext, StakingDataContext, TokenInfoContext, WalletsContext } from "./context"
import { error } from "console"



export const Layout = ({ setToken, setSessionToken, token }: { setToken: any, setSessionToken: any, token: any }) => {

    const { walletsContext, setWalletsContext } = useContext(WalletsContext)
    const { networkContext, setNetworkContext } = useContext(NetworkDataContext)
    const { programContext, setProgramContext } = useContext(ProgramContext)
    const { tokenInfoContext, setTokenInfoContext } = useContext(TokenInfoContext)
    const { stakingContext, setStakingContext } = useContext(StakingDataContext)
    const { chatContext, setChatContext } = useContext(ChatContext)
    const [a, setA] = useState<boolean>(false)

    const path = useLocation()

    const chatUpdate = () => {

    }

    useEffect(() => {
        const getAllWallets = () => {
            axios.post(SERVER_URL + 'admin/wallet/get/all', {}, { headers: HEADERS })
                .then((response) => {
                    console.log(response)
                    setWalletsContext(response.data)
                    // if (a == true) {
                    //     setA(false)
                    //     setInterval(() => {
                    //         console.log(path.pathname)
                    //         if (path.pathname.includes('chat')) {
                    //             axios.post(SERVER_URL + 'admin/wallet/get/all', {}, { headers: HEADERS })
                    //                 .then((response) => {
                    //                     console.log(response)
                    //                     setWalletsContext(response.data)
                    //                 })
                    //                 .catch((error) => {
                    //                     console.log(error)
                    //                 })
                    //         }
                    //     }, 1000)
                    // }
                })
                .catch((error) => {
                    console.log(error)
                })
        }
        const getProgramData = () => {
            axios.post(SERVER_URL + 'system/get', { keyData: "swap_percent" }, { headers: HEADERS })
                .then((response) => {
                    console.log(response)
                    setProgramContext({ "swapPercent": response.data.valueData })
                })
                .catch((error) => {
                    console.log(error)
                })
        }
        const getAllTokenInfo = () => {
            axios.post(SERVER_URL + 'token/info/get/all', {}, { headers: HEADERS })
                .then((response) => {
                    console.log(response)
                    setTokenInfoContext(response.data)
                })
                .catch((error) => {
                    console.log(error)
                })
        }
        const getAllStakings = () => {
            axios.post(SERVER_URL + 'stake/get/all', {}, { headers: HEADERS })
                .then((response) => {
                    console.log(response)
                    setStakingContext(response.data)
                })
                .catch((error) => {
                    console.log(error)
                })
        }
        const getAllNetworks = () => {
            axios.post(SERVER_URL + 'network/get', {}, { headers: HEADERS })
                .then(response => {
                    setNetworkContext(response.data)
                })
                .catch((e) => {})
        }
        getAllWallets()
        getProgramData()
        getAllTokenInfo()
        getAllStakings()
        getAllNetworks()
    }, [])


    const logoutUser = () => {
        deleteToken(setToken, setSessionToken, token)
    }

    function deleteToken(setToken: any, setSessionToken: any, token: any) {
        setSessionToken(undefined)
        setToken(undefined)
        sessionStorage.removeItem('token')
    }

    return (
        <div className="App">
            <div className="page-wrapper null compact-wrapper">
                <div className="page-body-wrapper">
                    <div className="page-body">
                        <div className="d-flex">
                            <div className="navbar-brand-box horizontal-logo">
                                <div>
                                    <button style={{
                                        padding: "4px",
                                        fontSize: "14px",
                                        border: "1px solid #333333",
                                        borderRadius: "4px"
                                    }} onClick={logoutUser}>Logout</button>
                                </div>
                            </div>
                        </div>
                        <Outlet />
                    </div>
                </div>
            </div>
        </div>
    )
}