export const SERVER_URL = "https://app.tadmin.xyz/";

export const HEADERS = {
    'Content-Type': 'application/json',
    "Access-Control-Allow-Origin": "*"
}

export const CORE_URL = "https://core.tadmin.xyz/";

export const STATIC_URL = "https://static.tadmin.xyz/static/"
