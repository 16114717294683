import { useContext, useState } from "react"
import { useNavigate } from "react-router-dom"
import { WalletsContext, TokenInfoContext, NetworkDataContext, StakingDataContext } from "../context"
import { TStakeInfo, TStakePool } from "../types"
import { StakingInfoEditModal } from "./StakingInfoEditModal"
import { StakingInfoAddModal } from "./StakingInfoAddModal"




export const StakingsPoolPage = () => {
    
    const navigate = useNavigate()
    const { walletsContext, setWalletsContext } = useContext(WalletsContext)
    const { tokenInfoContext, setTokenInfoContext } = useContext(TokenInfoContext)
    const { networkContext, setNetworkContext } = useContext(NetworkDataContext)
    const { stakingContext, setStakingContext } = useContext(StakingDataContext)
    const [modalOpen2, setModalOpen2] = useState<boolean>(false)
    const [modalOpen, setModalOpen] = useState<boolean>(false)

    const [selectedStakingPool, setSelectedStakingPool] = useState<TStakeInfo>()

    return (
        <>
            <div className="container-fluid">
                <div className="page-title">
                    <div className="row">
                        <div className="col-sm-6">
                            <h3>Стейкинги приложения</h3>
                        </div>
                        <div className="col-sm-6">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item">
                                    <a onClick={() => navigate('/')} data-bs-original-title="" title="">Список кошельков</a>
                                </li>
                                <li className="breadcrumb-item">
                                    <a data-bs-original-title="" title="">Информация о стейкингах</a>
                                </li>
                            </ol>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="card">
                            <div className="card-header" style={{ display: "flex", justifyContent: "space-between" }}>
                                <h4>Список стейкингов</h4>
                                <input className="jsgrid-button jsgrid-mode-button jsgrid-insert-mode-button" type="button" onClick={() => setModalOpen2(true)} />
                            </div>
                            <div className="card-body">
                                <div className="jsgrid">
                                    <div className="jsgrid-grid-body">
                                        <table className="jsgrid-table">
                                            <thead style={{ borderBottom: "1px solid #e9e9e9;" }}>
                                                <tr className="jsgrid-header-row">
                                                    <th className="jsgrid-header-cell jsgrid-header-sortable default">ID</th>
                                                    <th className="jsgrid-header-cell jsgrid-header-sortable default">Сеть</th>
                                                    <th className="jsgrid-header-cell jsgrid-header-sortable default">Токен</th>
                                                    <th className="jsgrid-header-cell jsgrid-header-sortable default">APR</th>
                                                    <th className="jsgrid-header-cell jsgrid-header-sortable default">Время блокировки</th>
                                                    <th className="jsgrid-header-cell jsgrid-header-sortable default">Минимальная сумма</th>
                                                    <th className="jsgrid-header-cell jsgrid-header-sortable action">Действие</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {stakingContext?.reverse().map((sp: TStakeInfo) => <StakingPoolToken stakingPool={sp} modalOpen={modalOpen} setModalOpen={setModalOpen} setSelectedStakingPool={setSelectedStakingPool} />)}
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <StakingInfoEditModal data={{modalOpen: modalOpen, setModalOpen: setModalOpen, stakingInfo: selectedStakingPool, setStakingInfo: selectedStakingPool}} />
            <StakingInfoAddModal data={{modalOpen: modalOpen2, setModalOpen: setModalOpen2}} />
        </>
    )
}


const StakingPoolToken = ({ modalOpen, setModalOpen, stakingPool, setSelectedStakingPool }: { modalOpen: boolean, setModalOpen: any, stakingPool: TStakeInfo, setSelectedStakingPool: any }) => {
    return (
        <tr className="jsgrid-row">
            <td className="jsgrid-cell default">{stakingPool?.id}</td>
            <td className="jsgrid-cell default">{stakingPool?.tokenInfoModel?.networkName}</td>
            <td className="jsgrid-cell default">{stakingPool?.tokenInfoModel?.name}</td>
            <td className="jsgrid-cell default">{stakingPool?.apy}</td>
            <td className="jsgrid-cell default">{stakingPool?.lockTimeAsDay}</td>
            <td className="jsgrid-cell default">{stakingPool?.minAmount}</td>
            <td className="jsgrid-cell jsgrid-control-field jsgrid-align-center action">
                <input className="jsgrid-button jsgrid-edit-button" type="button" title="" data-bs-original-title="Edit" aria-label="Edit" onClick={() => {setSelectedStakingPool(stakingPool); setModalOpen(true)}} />
                <input className="jsgrid-button jsgrid-delete-button" type="button" title="" data-bs-original-title="Delete" aria-label="Delete" />
            </td>
        </tr>
    )
}